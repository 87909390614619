import styled from 'styled-components';

const Body02 = styled.p`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-size: 15px;
    font-family: ${ ({ bold }) => bold ? 'bold' : 'regular'};
    letter-spacing: 0px;
    line-height: 20px;
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };
`;

export default Body02;