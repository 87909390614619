import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Headline01 from '../common/typography/Headline01';

const Story = () => {
    const userSettings = useSelector(state => state.userSettings.userSettings);
    const abbr = userSettings?.displayName?.charAt(0) || "A";

    return (
            <Root>
                { userSettings?.logoUrl ? 
                    <img src={ userSettings.logoUrl } alt="reaction menu" />
                    : <LogoPlaceholder>
                        <StyledHeadline01>
                            { abbr }
                        </StyledHeadline01>
                    </LogoPlaceholder>
                }
            </Root>
    )};

export default Story;

const Root  = styled.div`
	margin: -32px auto 20px;
    height: auto;
    position: relative;
    width: calc(100% - 100px - 100px);
	max-width: 280px;
    z-index: 2;
`;

const StyledHeadline01  = styled(Headline01)`
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

`;

const LogoPlaceholder  = styled.div`
	position: relative;
    border-radius: 50%;
    background: ${ ({ theme }) => theme.brandOverlay };
    width: 100%;
    padding-bottom: 100%;

`;