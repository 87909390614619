import { withRouter } from 'react-router';
import db from '../config/firebase';
import React, { useState } from 'react';
import styled, {useTheme} from 'styled-components';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase';

import Button from '../components/common/Button';
import Input from '../components/common/Input';
import Headline06 from '../components/common/typography/Headline06';
import Body01 from '../components/common/typography/Body01';
import Body02 from '../components/common/typography/Body02';
import { landingRoute, termsUrl, privacyUrl } from '../helpers/routes';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../img/logo.svg';

const SignUp = () => {
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ displayName, setDisplayName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ passwrd, setPassword ] = useState('');

    const history = useHistory();
    const theme = useTheme();

	const handleSignUp = async event => {
            event.preventDefault();
            setLoading(true);

			const { email, password } = event.target.elements;

            const socials = {
                wifi: 'wifi password',
                location: 'https://maps.app.goo.gl/oQUa1sRCEdLieTgU9',
                instagram: 'https://www.instagram.com/reactionmenu',
                facebook: 'https://www.facebook.com/reaction.menu',
                email: 'contact@reaction.menu',
                whatsapp: '+35799881812',
                googleBusinessId: 'ChIJ4dTjb_4y5xQRF7roh5_Lrh8'
            };

            const newCategory = {
                id: uuidv4(),
                translated: {
                    en: 'Breakfast',
                    el: 'Πρωινό',
                    ru: 'Завтрак',
                },
                hidden: false,
                timestamp: firebase.firestore.Timestamp.now()
            };

			try {
				const { user } = await db
				.auth()
				.createUserWithEmailAndPassword(email.value, password.value);
                
                if (user) {
                    const uid = db.auth().currentUser.uid;
                    const userDocRef = db.firestore().collection('users').doc(uid);

                    userDocRef
                    .set({
                        displayName: displayName || 'Store name',
                        socials: socials,
                        categories: [newCategory]
                    }, { merge: true });
                    
				    history.push(`/${ user.uid }`)
			    }
            } catch (error) {
				setLoading(false);
                setError(error.message);
			}
};

    return (
        <>
        <Root>
            <a href={ landingRoute }>
                <StyledLogo />
            </a>

            <Headline06 bold>
                Welcome to reaction.menu!
            </Headline06>

            <StyledBody02 color={ theme.textSecondary } center>Create your account & get started in minutes</StyledBody02>

            <Form onSubmit={ handleSignUp }>
                <Input 
                    name="displayName"
                    type="text"
                    value={ displayName }
                    onChange={ (e) => setDisplayName(e.target.value) }
                    label="Store name"
                    id="Store name" 
                    placeholder="Store name" 
                />

                <Input name="email"
                    type="email"
                    value={ email }
                    label="Email"
                    id="Email" 
                    onChange={ (e) => setEmail(e.target.value) }
                    placeholder="Type your email"
                />

                <Input name="password"
                    type="text"
                    value={ passwrd }
                    label="Password"
                    id="Password" 
                    onChange={ (e) => setPassword(e.target.value) }
                    placeholder="Create password"
                />

                {error && <Body01 color={ theme.red }>{ error }</Body01>}

                <StyledButton label={ loading ? "Creating..." : "Get started" }
                    type="submit"
                    primary
                    loading={ loading }
                    width="100%"
                />
            </Form>

            <StyledBody02 color={ theme.textSecondary}>Already have an account? <StyledLink to="/signin">Login</StyledLink> to continue</StyledBody02>
            <StyledBody02 center color={ theme.textSecondary}>
                By creating an account, <br />
                you agree to our <ExternalLink href={termsUrl} target="_blank" rel="noopener noreferrer">Terms of service</ExternalLink> & <ExternalLink href={privacyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</ExternalLink></StyledBody02>
        </Root>
        </>
    );
};

export default withRouter(SignUp);

const StyledLogo  = styled(Logo)`
   width: 64px;
   height: 64px;
   border-radius: 50%;
   margin-bottom: 24px;
`;


const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;


const StyledButton  = styled(Button)`
   margin-top: 12px;
`;

const StyledLink = styled(Link)`
    color: ${ ({ theme }) => theme.text };
    text-decoration: underline;
    font-family: bold;
`;

const ExternalLink = styled.a`
    color: ${ ({ theme }) => theme.textSecondary };
    text-decoration: none;
    font-family: regular;
`;

const StyledBody02 = styled(Body02)`
    margin: 24px auto;
`;

const Root = styled.div`
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        justify-content: flex-start;
        margin: 80px auto;
        width: 80%;
        padding-bottom: 80px;
    }
`;
