import React, { useState } from 'react';
import styled from 'styled-components';

import Body02 from '../../common/typography/Body02';
import SubscriptionModal from  './settings/SubscribeModal'

const SubscribePanel = ({}) => {
    const [ open, setOpen ] = useState(false);

    const handleClick = async () => {
        setOpen(true)
    };

    return (
        <>
            <Root>
                <Body02 center>
                    To activate your menu, you must <SubscribeButton onClick={handleClick}>Subscribe</SubscribeButton> to a plan
                </Body02>
            </Root>   

            <SubscriptionModal open={ open }
                onClose={() => setOpen(false)}
            /> 
        </>
    )
}

export default SubscribePanel;

const Root = styled.div`
    width: 100%;
    padding: 0 16px;
    background-color: ${ ({ theme }) => theme.yellow };
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SubscribeButton = styled.button`
    background: none;
    border: none;
    color: ${({ theme }) => theme.text}; 
    cursor: pointer;
    text-decoration: underline; 
    padding: 0; 
    font: inherit; 
    
    &:hover {
    }
`;