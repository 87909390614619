import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

import ModalBottom from '../../../common/ModalBottom';

import Button from '../../../common/Button';
import Body01 from '../../../common/typography/Body01';
import Body02 from '../../../common/typography/Body02';

const BillingModal = ({
    open,
    onClose,
    subscription
}) => {
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const theme = useTheme();
    const { t } = useTranslation();

    const openStripe = async () => {
        setLoading(true);
        setError(false);
    
        try {
                const functionRef = firebase
                    .app()
                    .functions("europe-central2")
                    .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    
                const { data } = await functionRef({
                    returnUrl: window.location.origin
                });
    
                if (data?.url) {
                    window.location.assign(data.url);
                } else {
                    setError(true);
                }
            }
        catch (err) {
            console.error("Billing error:", err);
            setError(true);
            setLoading(true);
        }
    };

    const renderSubscriptionStatus = () => {
        switch (subscription?.status) {
            case 'past_due':
                return (
                    <>
                        <StyledBody01 bold color={theme.red}>
                            We couldn't process your payment
                        </StyledBody01>
                        <Body02>
                            Your subscription remains active, 
                            but please update your payment details to avoid interruptions
                        </Body02>
                    </>
                );
            case 'active':
                return (
                    <>
                        <StyledBody01 bold>
                            Active
                        </StyledBody01>
                        <Body02>
                            Your subscription is active! Thank you for being with us
                        </Body02>
                    </>
                );
            case 'trialing':
                return (
                    <>
                        <StyledBody01 bold>
                            Trialing
                        </StyledBody01>
                        <Body02>
                            Enjoy your free trial! No action is needed right now
                        </Body02>
                    </>
                );
            default:
                return null;
        }
    };

    return (
            <ModalBottom open={ open }
                title={ t('Subscription') }
                onClose={ onClose }
            >
            <Wrapper>
                {renderSubscriptionStatus()}

                {error && <Body01 color={ theme.red }>{ error }</Body01>}

                <StyledButton label={ loading ? "Please wait..." : (subscription?.status === 'canceled' ? "View billing history" : "Manage subscription") }
                    primary
                    loading={ loading }
                    width="100%"
                    onClick={ openStripe }
                />
            </Wrapper>
            </ModalBottom>
)}

export default BillingModal;

const Wrapper = styled.div`
  padding: 16px;
`;


const StyledBody01 = styled(Body01)`
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
`;
