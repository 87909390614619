import styled from 'styled-components';
import React from 'react';

import LoadingSpinner from './Loadings/LoadingSpinner';

const Button = ({
    className,
    disabled,
    label,
    onClick,
    red,
    primary,
    loading,
    width,
    icon,
    border
}) => {

    return (
        <Root className={ className }
            disabled={ disabled }
            onClick={ onClick }
            red={ red }
            primary={ primary }
            loading={ loading ? "true" : undefined } 
            width={ width }
            border={ border }
        >
            { icon && <Icon>{icon}</Icon> }

            { label }

            { loading && <StyledLoadingSpinner /> }
        </Root>
    );
}

export default Button;

const Root = styled.button`
    align-items: center;
    background: ${ ({ theme, primary }) => primary ? theme.text : 'transparent' };
    border: 1px solid ${ ({ theme, border }) => border ? theme.border : 'transparent' };;
    border-radius: 4px;
    color: ${ ({ theme, red, primary }) => red ? theme.red : primary ? theme.body : theme.text };
    cursor: pointer;
    display: flex;
    font-family: bold, Arial;
    justify-content: center;
    font-size: 17px;
    margin: 0;
    outline: none;
    padding: ${ ({ primary, border }) => primary || border ? '16px' : 0 };
    pointer-events: ${ ({ loading }) => loading ? 'none' : '' };
    position: relative;
    width: ${ ({ width }) => width ? width : 'auto'};
    text-transform: ;
    letter-spacing: -0.1px;

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
    position: absolute;
    right: 16px;
`;

const Icon = styled.div`
    position: absolute;
    left: 16px;

    svg{
        width: 18px; 
        height: 18px; 
    }
`;
