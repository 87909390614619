import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';

import { AuthContext } from '../Auth';
import { receivedCategories } from '../components/features/categories/categoriesSlice';
import { receivedUserSettings } from '../components/features/admin/settings/userSettingsSlice';
import { receivedItems } from '../components/features/items/itemsSlice';
import Cart from '../components/features/cart/Cart'; 
import Categories from '../components/features/categories/Categories';
import db from '../config/firebase';
import Footer from '../components/common/Footer';
import Header from '../components/features/header/Header';
import HeaderOfUser from '../components/features/admin/HeaderOfUser';
import Navigation from '../components/features/Navigation';
import Skeleton from '../components/common/Loadings/Skeleton'; 
import Socials from '../components/features/Socials'; 
import Story from '../components/features/Story'; 
import NoMatchPage from './NoMatchPage'; 
import NoSubscriptionPage from './NoSubscriptionPage'; 
import Logo from '../components/features/Logo';

export const inactiveStatuses = ['canceled', 'incomplete', 'incomplete_expired'];
export const activeStatuses = ['active', 'trialing', 'past_due'];

export const isSubscriptionInactive = (status) => {
	return inactiveStatuses.includes(status);
};

const Tenant = () => {
	firebase.firestore.setLogLevel('debug');

	const initialLanguageActive = window.localStorage.getItem('activeLanguage') || 'en';

    const dispatch = useDispatch();

	const items = useSelector(state => state.items.items);

	const [ subscriptionLoading, setSubscriptionLoading ] = useState(true);
	const [ dataLoading, setDataLoading ] = useState(true);
	const [ activeLanguage, setActiveLanguage ] = useState(initialLanguageActive);
	const [ languagesOpen, setLanguagesOpen ] = useState(false);
	const [userExists, setUserExists] = useState(true);
	const [subscription, setSubscription] = useState();

	const { i18n } = useTranslation();

	const changeLanguage = lng => {
        i18n.changeLanguage(lng);

        setActiveLanguage(lng);
    };

    const languages = [ {
        name: 'en',
        action: () => { changeLanguage('en'); setLanguagesOpen(false) }
    }, {
        name: 'el',
        action: () => { changeLanguage('el'); setLanguagesOpen(false) }
    }, {
		name: 'ru',
		action: () => { changeLanguage('ru'); setLanguagesOpen(false) }
	} ];

    useEffect(() => {
        window.localStorage.setItem('activeLanguage', activeLanguage)
    }, [activeLanguage]);

	const { currentUser } = useContext(AuthContext);

	let location = useLocation();
	const url = location.pathname;
	const idFromUrl = url?.slice(1);

	const docRef = db.firestore().collection("users").doc(idFromUrl);

	const fetchSubscriptionData = async () => {
		setSubscriptionLoading(true); // Set loading to true when starting to fetch data
		try {
			const subscriptionSnapshot = await docRef.collection('subscriptions')
				.orderBy('created', 'desc') 
				.limit(1) // Limit the result to only the most recent subscription
				.get();
	
			if (!subscriptionSnapshot.empty) {
				const mostRecentSubscription = subscriptionSnapshot.docs[0].data(); 
				setSubscription(mostRecentSubscription); 
			} else {
				setSubscription(null); 
			}
		} catch (error) {
			console.error("Error fetching subscriptions:", error);
		}
		setSubscriptionLoading(false); 
	};

	const getData = async () => {
		docRef.onSnapshot(doc => {
			const data = doc.data();
			if (!data) {
				setDataLoading(false);
				setUserExists(false);
				return;
			}
			setDataLoading(false);
			setUserExists(true);
			dispatch(receivedUserSettings(data))

			const categories = (data?.categories || []).map(cat => ({
				hidden: cat.hidden,
				id: cat.id,
				translated: cat.translated,
				timestamp: cat.timestamp
			}))
			.sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0));
			dispatch(receivedCategories(categories));
			
			const items = (data?.items || []).map(item => ({
				id: item.id,
				category: item.category, 
				hidden: item.hidden,
				imageUrl: item.imageUrl,
				price: item.price, 
				timestamp: item.timestamp,
				translated: item.translated,
				vegan: item.vegan,
				vegetarian: item.vegetarian
			}))
			.sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0));
			dispatch(receivedItems(items));
		}
		,(error) => {
			alert(error);
			setDataLoading(false);
			setUserExists(false);
		});
	};

	useEffect(() => {
		fetchSubscriptionData();
		getData();
	}, []);

	const user = currentUser && currentUser.uid === idFromUrl;

	if (subscriptionLoading || dataLoading) {
		return <Skeleton />;
	}

	if (!userExists) {
		return <NoMatchPage />;
	}

	if (!user && (!subscription || isSubscriptionInactive(subscription?.status))) {
		return <NoSubscriptionPage />;
	}

	return (
		<Root>
			<Wrap>
				<HeaderOfUser user={ user }
					lang={ activeLanguage }
					subscription={ subscription }
				/>

				<Header activeLanguage={ activeLanguage }
					languages={ languages }
					languagesOpen={ languagesOpen }
					onLanguagesClose={ () => setLanguagesOpen(false) }
					onLanguagesOpen={ () => setLanguagesOpen(true) }
					user={ user }
					subscription={ subscription }
				/>

				<>
					<Logo />

					<Socials />
				
					<Story />

					<Navigation lang={ activeLanguage }
						user={ user }
						subscription={ subscription }
					/>

					<Categories items={ items }
						userId={ idFromUrl }
						lang={ activeLanguage }
					/>
				</>
			</Wrap>
			<Cart lang={ activeLanguage } />

			<Footer user={ user } />
		</Root>
    );
}

export default Tenant;

const Root = styled.div`
	background-color: ${ ({ theme }) => theme.body };
	min-height: 100vh;
	position: relative;
 	width: ${ ({ theme }) => theme.tabletBreakpoint };
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    margin: 0 auto;

	 @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 100%;
		box-shadow: none;
    }
`;

const Wrap = styled.div`
	padding-bottom: 168px;
`;