import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Body02 from './typography/Body02';

const Footer = ({ user }) => {
   
    return (
        <Root>
            {user ? (
                    <StyledBody02>
                        Need help?  <LandingLink href="https://reaction.menu#contacts" target="_blank">Contact us</LandingLink>
                    </StyledBody02>
                ) : (
                <>
                    <StyledBody02>
                        Are you a store owner?
                    </StyledBody02>

                    <StyledBody02>
                        Check about <LandingLink href="https://reaction.menu" target="_blank">reaction.menu
                    </LandingLink>
                    {` or `}
                    <Link to="/signin">
                        Login
                    </Link>
                    </StyledBody02>
                </>
            )}
        </Root>
    )}

export default Footer;

const Root  = styled.div`
    align-items: center;
    background-color: ${ ({ theme }) => theme.body };
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 168px;
    justify-content: center;
    text-align: center;
    width: 100%;
    position: absolute;
`;

const LandingLink = styled.a`
    font-family: bold;
    color: ${ ({ theme }) => theme.text };
    text-decoration: underline;
`;

const StyledBody02  = styled(Body02)`
    white-space: nowrap;
    text-align: center;
    margin-bottom: 4px;
    color: ${ ({ theme }) => theme.textSecondary };

    span {
        font-family: extraBold;
        color: ${ ({ theme }) => theme.text };
    }
`;
