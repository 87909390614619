import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Category from './Category';

const Categories = ({
    items,
    invisible,
    userId,
    lang
}) => {
    const categories = useSelector(state => state.categories.categories);
    return (
        <Root>
            { categories && categories.map(category => (
                <Category key={ category.id }
                    category={ category }
                    items={ items }
                    invisible={ invisible }
                    userId={ userId }
                    categories={ categories }
                    lang={ lang }
                /> 
            )) }
        </Root>
    );
}

export default Categories;

const Root  = styled.div`
    position: relative;
`;
