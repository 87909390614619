import React from 'react';
import styled from 'styled-components';

import LoadingBar from './LoadingBar';

const Skeleton = () => {
    return (
        <Root>
            <Navigation>
                <LoadingBar />
            </Navigation>

            <Title>
                <LoadingBar />
            </Title>

            <StyledLoadingBar height="100px"
                width="calc(100% - 32px)"
            />

            <StyledLoadingBar height="100px"
                width="calc(100% - 32px)"
            />

        </Root>
    );
}

export default Skeleton;

const Root  = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: ${ ({ theme }) => theme.tabletBreakpoint };
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    min-height: 100vh;
    
    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 100%;
		box-shadow: none;
    }
`;

const Navigation  = styled.div`
    width: 100%;
    padding: 24px 16px;
    border-bottom: 1px solid ${ ({ theme }) => theme.border };
`;

const Title  = styled.div`
    width: 100%;
    padding: 24px 16px 12px;
`;

const StyledLoadingBar  = styled(LoadingBar)`
    margin: 24px 0 0;
`;
