import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Share from './Share';
import Languages from './Languages';
import Headline06 from '../../common/typography/Headline06';

const getTopPosition = ({ user, subscription }) => {
    if (user) {
        if (subscription) {
            return (subscription.status === 'active' || 
                subscription.status === 'trialing'
            ) ? '64px' : '100px';
        }
        return '100px';
    }
    return '0';
};

const Header = ({
    activeLanguage,
    languages,
    languagesOpen,
    onLanguagesClose,
    onLanguagesOpen,
    user,
    subscription
}) => {
    const userSettings = useSelector(state => state.userSettings.userSettings);

    const [visible, setVisible] = useState(false);

    const listenToScroll = () => {
        let heightToShowFrom = 280;
        const winScroll = document.body.scrollTop || 
            document.documentElement.scrollTop;
           
        if (winScroll > heightToShowFrom) { 
            !visible &&      // to limit setting state only the first time         
            setVisible(true);
        } else {
            setVisible(false);
        }  
      };

    useEffect(() => {   
            window.addEventListener("scroll", listenToScroll);
            return () => 
               window.removeEventListener("scroll", listenToScroll)
            ; 
      }, [])

      return (
        <Root user={ user } subscription={ subscription }>
            <StyledLanguages activeLanguage={ activeLanguage }
                languages={ languages }
                open={ languagesOpen }
                onOpen={ onLanguagesOpen }
                onClose={ onLanguagesClose }
            />

            { visible && <Headline06 bold ellipsis>{ userSettings.displayName }</Headline06> }

            <StyledShare />
        </Root>
    );
}

export default Header;

const Root  = styled.div`
    align-items: center;
    background: ${ ({ theme }) => theme.body };
    display: flex;
    justify-content: center;
    position: sticky;
    width: 100%;
    height: 64px;
    top: ${props => getTopPosition(props)};
    z-index: 2;
`;

const StyledLanguages = styled(Languages)`
    position: absolute;
    left: 16px;
`;

const StyledShare = styled(Share)`
    position: absolute;
    right: 16px;
`;