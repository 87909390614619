import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Body02 from '../../common/typography/Body02';
import BillingModal from  './settings/BillingModal'

const PastDuePanel = ({subscription}) => {
    const theme = useTheme();
    const [ open, setOpen ] = useState(false);

    const handleClick = async () => {
        setOpen(true)
    };

        return (
        <>
            <Root>
                <StyledBody02 center color={ theme.body }>
                    Payment issue! <SubscribeButton onClick={handleClick}>Update</SubscribeButton> to avoid interruption
                </StyledBody02>
            </Root>   

            <BillingModal open={ open }
                onClose={() => setOpen(false)}
                subscription={subscription}
            /> 
        </>
    )
}

export default PastDuePanel;

const Root = styled.div`
    width: 100%;
    padding: 0 16px;
    background-color: ${ ({ theme }) => theme.red };
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SubscribeButton = styled.button`
    background: none;
    border: none;
    color: ${({ theme }) => theme.body}; 
    cursor: pointer;
    text-decoration: underline; 
    padding: 0; 
    font-family: bold;
    font-size: inherit;

    
    &:hover {
    }
`;

const StyledBody02 = styled(Body02)`

`;