import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-scroll'

import Body01 from '../../components/common/typography/Body01';

const getTopPosition = ({ user, subscription }) => {
    if (user) {
        if (subscription) {
            return (subscription.status === 'trialing' || 
                subscription.status === 'active'
            ) ? '128px' : '164px';
        }
        return '164px';
    }
    return '64px';
};

const Navigation = ({
    user,
    lang,
    subscription
}) => {
    const categories = useSelector(state => state.categories.categories);

    return (
        <Root user={ user } 
            subscription={ subscription }
        >
             { categories && categories.map((category) => (
                    (user || !category.hidden) &&
                    <StyledLink to={ category.id }
                        spy={ true }
                        smooth={ true }
                        offset={ user ? -186 : -120 }
                        activeClass='active'
                        key={ category.id }
                        >
                            <StyledBody01>
                                { category?.translated ? category.translated[lang] : '' }
                            </StyledBody01>
                    </StyledLink>
                )) }
        </Root>
    )
};

export default Navigation;

const Root = styled.div`
    align-items: center;
    background: ${ ({ theme }) => theme.body };
    display: flex;
    overflow-x: auto;
    padding-bottom: 8px;
    position: sticky;
    scrollbar-width: none;
    top: ${props => getTopPosition(props)};
    z-index: 2;
    
    ::-webkit-scrollbar {
        display: none;
      }
`;

const StyledBody01 = styled(Body01)`
    color: ${ ({ theme }) => theme.text };
    white-space: nowrap;
    line-height: 1;
    align-items: center;
    display: flex;
`;

const StyledLink = styled(Link)`
    padding: 12px 16px;
    border-radius: 32px;
    border: 1px solid ${ ({ theme }) => theme.border };
    margin-right: 12px;
    text-decoration: none;

    &:first-of-type {
        margin-left: 16px;
    }

    &.active {
        border: 2px solid ${ ({ theme }) => theme.text };
    }
`;
