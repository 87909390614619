import React, { useState, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import db from '../../../../config/firebase';

import ModalBottom from '../../../common/ModalBottom';
import { AuthContext } from '../../../../Auth';
import { ReactComponent as CheckIcon } from '../../../../img/check.svg';
import Button from '../../../common/Button';
import RadioInput from '../../../common/RadioInput';
import Body01 from '../../../common/typography/Body01';
import Body02 from '../../../common/typography/Body02';

const SubscribeModal = ({
    open,
    onClose,
    subscription
}) => {
    const YEARLY_PRICE = 'price_1Jig7qFEWEsMSzk33GHKmWrP';
    const MONTHLY_PRICE = 'price_1Jig79FEWEsMSzk3SyrBoKmY';
    
    const features = [
        "Unlimited categories",
        "Unlimited items with descriptions",
        "Unlimited images",
        "Promo banner",
        "Stop list for out of stock categories & items",
        "Wifi password",
        "Links to socials",
        "Link to Email, WhatsApp and more",
        "Share button with QR code",
        "Quality control with Google reviews",
        "Translation to En, Gr, Ru and more"
    ];

    const [ price, setPrice ] = useState(MONTHLY_PRICE);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const { currentUser } = useContext(AuthContext);
    const theme = useTheme();
    const { t } = useTranslation();

    const canceledSubscription = subscription && subscription?.status === 'canceled';

    const handleCheckout = async (e) => {
    e.preventDefault();    
    setLoading(true);

    try {
    const trialPeriodDays = canceledSubscription ? 0 : 14;
    
    const checkoutRef = await db
    .firestore()
    .collection("users")
    .doc(currentUser.uid)
    .collection("checkout_sessions")
    .add({
        price: price,
        trial_period_days: trialPeriodDays,
        success_url: `${ window.location.origin }/${ currentUser.uid }`,
        cancel_url: `${ window.location.origin }/${ currentUser.uid }`

    });
    // Wait for the CheckoutSession to get attached by the extension
    checkoutRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
            alert(`An error occured: ${error.message}`);
        }
        if (url) {
            window.location.assign(url);
        }
    })
    } catch (err) {
        setError(true);
    } 
}

    return (
            <ModalBottom open={ open }
                title={ t('Subscribe') }
                onClose={ onClose }
            >
            <Wrapper>
                <Body01 color={ theme.text }>Choose your plan</Body01>
                
                <form onSubmit={ handleCheckout }>
                    <Radios>
                        <Radio onClick={() => setPrice(MONTHLY_PRICE)}>
                            <StyledRadioInput label="Monthly"
                                value={ MONTHLY_PRICE }
                                onChange={ event => setPrice(event.target.value) }
                                checked={ price === MONTHLY_PRICE }
                            />
                            <StyledBody02 color={ theme.textSecondary }>
                                €29.00/month {!canceledSubscription && "after 14 day free trial"}
                            </StyledBody02>
                        </Radio>

                        <Radio onClick={() => setPrice(YEARLY_PRICE)}>
                            <Flex>
                                <StyledRadioInput label="Annual"
                                    value={ YEARLY_PRICE }
                                    checked={ price === YEARLY_PRICE }
                                    onChange={ event => setPrice(event.target.value) }
                                />
                                <Chip>
                                    Save €58
                                </Chip>
                            </Flex>

                            <StyledBody02 color={ theme.textSecondary }>
                             €24.17/month (billed €290/year)
                            </StyledBody02>
                        </Radio>
                    </Radios>

                    {error && <Body01 color={ theme.red }>{ error }</Body01>}

                    <Button label={ loading ? "Processing..." : (canceledSubscription ? "Subscribe" : "Try free for 14 days") }
                        type="submit"
                        primary
                        loading={ loading }
                        width="100%"
                    />
                </form>

                <Features>
                {features.map((feature, index) => (
                    <Item key={index}>
                        <StyledCheckIcon />
                        <Body02 color={theme.textSecondary}>{feature}</Body02>
                    </Item>
                ))}
            </Features>

            </Wrapper>
            </ModalBottom>
)}

export default SubscribeModal;

const Wrapper = styled.div`
  padding: 16px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 18px;
  height: 18px;
    fill: ${ ({ theme }) => theme.green};
`;

const Features = styled.div`
    margin: 24px 0 24px 8px;
    display: flex;
    gap: 4px;
    flex-direction: column;
`;

const Item = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`;

const Chip = styled.div`    
    align-items: center;
    background-color: ${ ({ theme }) => theme.brand };
    border-radius: 64px;
    color: ${ ({ theme }) => theme.body };
    display: flex;
    font-family: bold;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.25px;
    padding: 4px 8px;
`;

const StyledBody02 = styled(Body02)`
    margin-left: 36px;
`;

const Flex = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const Radios = styled.div`
    margin: 16px 0 24px;
`;

const Radio = styled.div`
    margin-bottom: 24px;
`;

const StyledRadioInput = styled(RadioInput)`

`;