import React from 'react';
import styled, {useTheme} from 'styled-components';
import { Link } from 'react-router-dom';

import { Centered } from '../../src/styles/global';
import Body02 from '../components/common/typography/Body02';
import Headline06 from '../components/common/typography/Headline06';
import { ReactComponent as Logo } from '../img/logo.svg';

import { landingRoute } from '../helpers/routes';

const NoSubscriptionPage = () => {
    const theme = useTheme();

    return (
        <Centered>
            <Root>
            <a href={ landingRoute }>
                <StyledLogo />
            </a>
            <Headline06 bold center>
                This menu is not active yet
            </Headline06>

            <Body02 color={ theme.textSecondary }>
                Are you a store owner?  <a href="https://reaction.menu#contacts" target="blank">Contact us</a>
            </Body02>

            <StyledLink to="/signin">Log in</StyledLink>
            </Root>
        </Centered>
    );
}

export default NoSubscriptionPage;

const Root = styled.div`
	display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
`;

const StyledLink = styled(Link)`
	margin-top: 20px;
`;

const StyledLogo  = styled(Logo)`
   width: 64px;
   height: 64px;
   border-radius: 50%;
   margin-bottom: 24px;
`;