import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Story = () => {
    const userSettings = useSelector(state => state.userSettings.userSettings);

    return (
        <>{ userSettings && userSettings.storyUrl && 
            <Root>
                <img src={ userSettings.storyUrl } alt="reaction menu" />
            </Root>
        }
        </>
    )};

export default Story;

const Root = styled.div`
    margin: 0 16px 16px;

    img {border-radius: 12px}
`;