import { configureStore } from '@reduxjs/toolkit';
import cartReducer from '../components/features/cart/cartSlice';
import itemsReducer from '../components/features/items/itemsSlice';
import categoriesReducer from '../components/features/categories/categoriesSlice';
import userSettingsReducer from '../components/features/admin/settings/userSettingsSlice';

export const store = configureStore({
    reducer: {
        cart: cartReducer,
        items: itemsReducer,
        categories: categoriesReducer,
        userSettings: userSettingsReducer
    }
});