import { withRouter } from "react-router";
import React, { useCallback, useContext, useState, useEffect } from "react";
import styled, {useTheme} from 'styled-components';
import { Link } from 'react-router-dom';

import { AuthContext } from '../Auth';
import { ReactComponent as Logo } from '../img/logo.svg';

import Button from '../components/common/Button';
import db from "../config/firebase";
import Input from '../components/common/Input';
import Body01 from '../components/common/typography/Body01';
import Body02 from '../components/common/typography/Body02';
import Headline06 from '../components/common/typography/Headline06';

import { landingRoute } from '../helpers/routes';

const SignIn = ({ history }) => {
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ passwrd, setPassword ] = useState('');

    const { currentUser } = useContext(AuthContext);
    const theme = useTheme();

	const handleLogin = useCallback(
		async event => {
            event.preventDefault();
            setLoading(true);

			const { email, password } = event.target.elements;

			try {
				await db
				.auth()
				.signInWithEmailAndPassword(email.value, password.value);

				history.push(`/${ currentUser.uid }`)
			} catch (error) {
				setLoading(false);
                setError(error.message);
			}
		},
	[history, currentUser]
);

useEffect(() => {
    if (currentUser) {
        history.push(`/${currentUser.uid}`);
    }
}, [currentUser, history]);

    return (
        <>
        <Root>
            <a href={ landingRoute }>
                <StyledLogo />
            </a>

            <Headline06 bold>
                Log in
            </Headline06>

            <StyledBody02 color={ theme.textSecondary } center>
                Welcome back! Log in to your reaction.menu account
            </StyledBody02>

            <Form onSubmit={ handleLogin }>
                <Input
                    name="email"
                    type="email"
					label="Email"
                    placeholder="Type your email"
                    id="Email"
                    onChange={ (e) => setEmail(e.target.value) }
                    value={ email }
                />

                <Input name="password"
                    type="password"
					label="Password"
                    id="Password"
                    value={ passwrd }
                    onChange={ (e) => setPassword(e.target.value) }
                    placeholder="Type your password"
                />
                
                <Body02 color={ theme.textSecondary}>
                    <StyledLink to="/passwordReset">Forgot password?</StyledLink>
                </Body02>

                {error && <Body01 color={ theme.red }>{ error }</Body01>}

                <StyledButton label="Log in"
                    type="submit"
                    primary
                    loading={ loading }
                    width="100%"
                />
            </Form>

            <StyledBody02 color={ theme.textSecondary}>or <StyledLink to="/signup">Create account</StyledLink></StyledBody02>
        </Root>
        </>
    );
};

export default withRouter(SignIn);

const StyledButton  = styled(Button)`
   margin-top: 12px;
`;

const StyledLogo  = styled(Logo)`
   width: 64px;
   height: 64px;
   border-radius: 50%;
   margin-bottom: 24px;
`;

const Root = styled.div`
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

const StyledLink = styled(Link)`
    text-decoration: underline;
`;

const StyledBody02 = styled(Body02)`
   margin: 24px 0;
`;