import React, { useState } from 'react';
import styled, {useTheme} from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Body02 from '../common/typography/Body02';
import Tooltip from '../common/Tooltip';

import { ReactComponent as PhoneIcon } from '../../img/call.svg';
import { ReactComponent as LocationIcon } from '../../img/location.svg';
import { ReactComponent as InstagramIcon } from '../../img/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../img/fb.svg';
import { ReactComponent as EmailIcon } from '../../img/mail.svg';
import { ReactComponent as WhatsAppIcon } from '../../img/whatsapp.svg';
import { ReactComponent as WifiIcon } from '../../img/wifi.svg';
import { ReactComponent as GoogleIcon } from '../../img/google.svg';

const Socials = () => {
    const socials = useSelector(state => state.userSettings.userSettings.socials);
    const { t } = useTranslation();
    const theme = useTheme();

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const socialLinks = [
        { value: socials?.instagram, icon: <InstagramIcon />, link: socials?.instagram || null },
        { value: socials?.facebook, icon: <FacebookIcon />, link: socials?.facebook || null },
        { value: socials?.whatsapp, icon: <WhatsAppIcon />, link: `https://wa.me/${socials?.whatsapp}` || null },
        { value: socials?.email, icon: <EmailIcon />, link: `mailto:${socials?.email}` || null },
        { value: socials?.location, icon: <LocationIcon />, link: socials?.location || null },
        { value: socials?.phone, icon: <PhoneIcon />, link: `tel:${socials?.phone}` || null }
    ];

    return (
        <Root>
            <Icons>
                { socials?.wifi &&  
                <Wifi>
                    <ListItem onClick={ () => setIsTooltipOpen(!isTooltipOpen)}>
                        <WifiIcon />
                    </ListItem>

                    <Tooltip isOpen={isTooltipOpen}
                        onClose={() => setIsTooltipOpen(false)}
                        text={socials?.wifi}
                    />
                </Wifi>
                }

                {socialLinks.map(({ value, icon, link }) => (
                    value ? ( 
                        <ListItem key={link}>
                            <Link href={link} target="_blank" rel="noopener noreferrer">
                                {icon}
                            </Link>
                        </ListItem>
                    ) : null
                ))}
            </Icons>

            {socials?.googleBusinessId &&
                <GoogleReview>
                    <Link href={`https://search.google.com/local/writereview?placeid=${socials.googleBusinessId}`} target="_blank" rel="noopener noreferrer">
                        <GoogleIcon />
                        { <Body02 color={theme.textSecondary}>{t('Review us on Google')}</Body02> }
                    </Link>
                </GoogleReview>
            }
        </Root>
    );
}

export default Socials;

const Root = styled.div`
    position: relative;
    margin: 0 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Wifi = styled.div`
    position: relative;
`;

const Icons = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 4px;
    flex-wrap: wrap;
    position: relative;
    padding: 0 16px;
`;

const Link = styled.a`
    justify-content: center;
    align-items: center;
    display: flex;
    text-decoration: none;
    gap: 8px;
`;

const ListItem = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: center;
    width: 40px;

    svg {
        fill: ${ ({ theme }) => theme.text };
        width: 26px;
        height: 26px;

        path { fill: ${ ({ theme }) => theme.text } }
    }
`;

const GoogleReview = styled.div`
    align-items: center;
    border-radius: 32px;
    border: 2px solid ${ ({ theme }) => theme.text };
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    height: 40px;
    justify-content: center;
    margin: 0 auto;
    padding: 0 16px;
    width: fit-content;

    svg {
        width: 18px;
        height: 18px;
    }
`;