import React from 'react';
import styled, { useTheme } from 'styled-components';

import AddButton from './AddButton';
import SettingsButton from './settings/SettingsButton';
import SubscribePanel from './SubscribePanel';
import PastDuePanel from './PastDuePanel';
import Body01 from '../../common/typography/Body01';
import {isSubscriptionInactive} from '../../../pages/Menu';

const HeaderOfUser = ({
    user,
    lang,
    subscription
}) => {  
    const theme = useTheme();

    if (user) {
        return (
            <Root>               
                {(!subscription || isSubscriptionInactive(subscription?.status)) ? <SubscribePanel subscription={subscription}/> : null}
                
                {subscription?.status === 'past_due' && 
                    <PastDuePanel subscription={subscription}/>
                }

                <AdminPanel>
                    <Body01 color={ theme.body } bold>
                        Admin mode
                    </Body01>
                    
                    <Controls>
                        <AddButton lang={ lang }/>

                        <SettingsButton subscription={subscription}/>
                    </Controls>
                </AdminPanel>
            </Root>
    )}

    return null;
}

export default HeaderOfUser;

const Root  = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;
`;

const AdminPanel  = styled.div`
    background-color: ${ ({ theme }) => theme.text };
    display: flex;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px;
`;

const Controls  = styled.div`
    display: flex;
    align-items: center;
`;